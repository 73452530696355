import {
  WalletIcon,
  DashboardIcon,
  DashboardBarIcon,
  HistoryBarIcon,
  ActivityLogBarIcon,
  SettingsBarIcon,
  UserManagementBarIcon,
  CurrencyTabIcon,
  CoinBarIcon,
  CryptoWithdrawTabIcon,
  APIKeysIcon,
} from "../../../icons";
import { GenericConstant } from "../../../utils/Constants";

const Users = {
  mainTitle: "User Management",
  title: "Users",
  classsChange: "mm-collapse",
  to: "/admin/users",
};
const Admins = {
  mainTitle: "User Management",
  title: "Admins",
  classsChange: "mm-collapse",
  to: "/admin/admins",
};

const UserMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: (
      <DashboardBarIcon className="w-100 h-100" style={{ padding: "1px" }} />
    ),
    to: "dashboard",
  },
  //Accounts
  {
    title: "Accounts",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon className="w-100 h-100" />,
    to: "accounts",
  },
  // Transaction History
  {
    title: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: "transaction-history",
  },
  //Settings
  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "settings",
  },
  //Activity
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "activity",
  },
];

const SidebarMenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <DashboardIcon />,
    to: "/admin/dashboard",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },

  {
    title: "Fiat Management",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon />,
    to: "/admin/fiat-management",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },

  {
    title: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: "/admin/transaction-history",
    roles: [GenericConstant._SUPER_ADMIN, GenericConstant._SUPERVISOR],
  },

  {
    title: "Users",
    subTitle: "Users",
    classsChange: "mm-collapse",
    iconStyle: <UserManagementBarIcon />,
    to: "/admin/users",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Coin Management",
    classsChange: "mm-collapse",
    iconStyle: <CoinBarIcon className="w-100 h-100" />,
    to: "/admin/coin-management",
    roles: [GenericConstant._SUPER_ADMIN],
  },
  {
    title: "Crypto Withdraw",
    classsChange: "mm-collapse",
    iconStyle: <CryptoWithdrawTabIcon className="w-100 h-100" />,
    to: "/admin/crypto-withdraw",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Currencies",
    classsChange: "mm-collapse",
    iconStyle: <CurrencyTabIcon className="w-100 h-100" />,
    to: "currencies",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "/admin/activity",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },

  {
    title: "Organisation",
    classsChange: "mm-collapse",
    iconStyle: <APIKeysIcon />,
    to: "/admin/organisation",
    roles: [GenericConstant._SUPER_ADMIN, GenericConstant._ADMIN],
  },

  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "/admin/settings",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
];

const SuperAdminMenuList = SidebarMenuList.map((route) =>
  route.to === "/admin/users"
    ? {
        ...route,
        title: "User Management",
        content: [Users, Admins],
      }
    : route
);

export const MenuList = {
  user: UserMenuList,
  superAdmin: SuperAdminMenuList,
  supervisor: SuperAdminMenuList.filter((route) =>
    route?.roles?.includes(GenericConstant._SUPERVISOR)
  ),
  admin: SidebarMenuList.filter((route) =>
    route?.roles?.includes(GenericConstant._ADMIN)
  ),
};
